import './About.svelte.css';
/* src/components/About.svelte generated by Svelte v3.46.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { Clue } from '../clue.js';
import { MAX_GUESSES } from '../utils.js';
import Row from './Row.svelte.js';

function create_fragment(ctx) {
	let div;
	let p0;
	let t5;
	let p1;
	let t6;
	let t7;
	let t8;
	let br0;
	let t9;
	let t10;
	let hr0;
	let t11;
	let p2;
	let row0;
	let t12;
	let p3;
	let t24;
	let p4;
	let t31;
	let hr1;
	let t32;
	let p5;
	let t36;
	let row1;
	let t37;
	let row2;
	let current;

	row0 = new Row({
			props: {
				rowState: "locked-in",
				fake: true,
				cluedLetters: [
					{ clue: Clue.Absent, letter: 'w' },
					{ clue: Clue.Absent, letter: 'o' },
					{ clue: Clue.Correct, letter: 'r' },
					{ clue: Clue.Elsewhere, letter: 'd' }
				]
			}
		});

	row1 = new Row({
			props: {
				rowState: "locked-in",
				fake: true,
				cluedLetters: [
					{ clue: Clue.Correct, letter: 'd' },
					{ clue: Clue.Correct, letter: 'a' },
					{ clue: Clue.Correct, letter: 'r' },
					{ clue: Clue.Absent, letter: 'k' }
				],
				annotation: "So close!"
			}
		});

	row2 = new Row({
			props: {
				rowState: "locked-in",
				fake: true,
				cluedLetters: [
					{ clue: Clue.Correct, letter: 'd' },
					{ clue: Clue.Correct, letter: 'a' },
					{ clue: Clue.Correct, letter: 'r' },
					{ clue: Clue.Correct, letter: 't' }
				],
				annotation: "Got it!"
			}
		});

	return {
		c() {
			div = element("div");
			p0 = element("p");
			p0.innerHTML = `This is a remake of the word game This is a remake of the word game <a href="https://www.powerlanguage.co.uk/wordle/"><i>Wordle</i></a>, which I think is based on the TV show <i>Lingo</i>.`;
			t5 = space();
			p1 = element("p");
			t6 = text("You get ");
			t7 = text(MAX_GUESSES);
			t8 = text(" tries to guess a target word.\n    ");
			br0 = element("br");
			t9 = text("\n    After each guess, you get Mastermind-style feedback.");
			t10 = space();
			hr0 = element("hr");
			t11 = space();
			p2 = element("p");
			create_component(row0.$$.fragment);
			t12 = space();
			p3 = element("p");

			p3.innerHTML = `<b class="svelte-1vkolc3">W</b> and <b class="svelte-1vkolc3">O</b> aren&#39;t in the target word at all.
    <br/> 
    <b class="green-bg svelte-1vkolc3">R</b> is correct! The third letter is <b class="green-bg svelte-1vkolc3">R</b>.
    <br/> 
    <strong>(There may still be a second R in the word.)</strong>`;

			t24 = space();
			p4 = element("p");

			p4.innerHTML = `<b class="yellow-bg svelte-1vkolc3">D</b> occurs <em>elsewhere</em> in the target word.
    <br/> 
    <strong>(Perhaps more than once.)</strong>`;

			t31 = space();
			hr1 = element("hr");
			t32 = space();
			p5 = element("p");
			p5.innerHTML = `Let&#39;s move the <b class="svelte-1vkolc3">D</b> in our next guess:`;
			t36 = space();
			create_component(row1.$$.fragment);
			t37 = space();
			create_component(row2.$$.fragment);
			attr(div, "class", "app-about svelte-1vkolc3");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p0);
			append(div, t5);
			append(div, p1);
			append(p1, t6);
			append(p1, t7);
			append(p1, t8);
			append(p1, br0);
			append(p1, t9);
			append(div, t10);
			append(div, hr0);
			append(div, t11);
			append(div, p2);
			mount_component(row0, p2, null);
			append(div, t12);
			append(div, p3);
			append(div, t24);
			append(div, p4);
			append(div, t31);
			append(div, hr1);
			append(div, t32);
			append(div, p5);
			append(div, t36);
			mount_component(row1, div, null);
			append(div, t37);
			mount_component(row2, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(row0.$$.fragment, local);
			transition_in(row1.$$.fragment, local);
			transition_in(row2.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(row0.$$.fragment, local);
			transition_out(row1.$$.fragment, local);
			transition_out(row2.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(row0);
			destroy_component(row1);
			destroy_component(row2);
		}
	};
}

class About extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default About;