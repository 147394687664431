function fallback(text) {
  prompt("You might want to update your browser... (Copy this)", text);
}
function copy(text, success) {
  if (!navigator.clipboard) {
    fallback(text);
  } else {
    try {
      navigator.clipboard.writeText(text).then(() => {
        success();
      }, () => {
        fallback(text);
      });
    } catch {
      fallback(text);
    }
  }
}
export default function share(text, success) {
  if (/android|iphone|ipad|ipod|webos|safari/i.test(navigator.userAgent) && !/firefox/i.test(navigator.userAgent)) {
    try {
      navigator.share({text}).catch((e) => {
        console.warn("navigator.share failed:", e);
        if (e instanceof Error && e.toString().includes("AbortError"))
          return;
        copy(text, success);
      });
    } catch (e) {
      console.warn("navigator.share failed:", e);
      if (e instanceof Error && e.toString().includes("AbortError"))
        return;
      copy(text, success);
    }
  } else {
    copy(text, success);
  }
}
