import './Share.svelte.css';
/* src/components/Share.svelte generated by Svelte v3.46.2 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	component_subscribe,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	space
} from "../../_snowpack/pkg/svelte/internal.js";

import { difficulty, gameProps, dark, colourBlind } from '../store.js';
import { MAX_GUESSES } from '../utils.js';
import share from '../share.js';

function create_if_block(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Share emoji results";
			attr(button, "class", "share-button svelte-rf4ekw");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			/*button_binding_1*/ ctx[8](button);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler_1*/ ctx[9]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			/*button_binding_1*/ ctx[8](null);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let button;
	let t1;
	let if_block_anchor;
	let mounted;
	let dispose;
	let if_block = /*state*/ ctx[0] !== 'playing' && create_if_block(ctx);

	return {
		c() {
			button = element("button");
			button.textContent = "Challenge a friend to this word";
			t1 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(button, "class", "share-button svelte-rf4ekw");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			/*button_binding*/ ctx[6](button);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[7]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*state*/ ctx[0] !== 'playing') {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(button);
			/*button_binding*/ ctx[6](null);
			if (detaching) detach(t1);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			mounted = false;
			dispose();
		}
	};
}

function buttonHint(button) {
	const og = button.innerText;
	button.innerText = `${og.startsWith('Share') ? 'Result' : 'Challenge link'} copied to clipboard`;

	setTimeout(
		() => {
			button.innerText = og;
		},
		2000
	);
}

function instance($$self, $$props, $$invalidate) {
	let $difficulty;
	let $gameProps;
	let $colourBlind;
	let $dark;
	component_subscribe($$self, difficulty, $$value => $$invalidate(10, $difficulty = $$value));
	component_subscribe($$self, gameProps, $$value => $$invalidate(11, $gameProps = $$value));
	component_subscribe($$self, colourBlind, $$value => $$invalidate(12, $colourBlind = $$value));
	component_subscribe($$self, dark, $$value => $$invalidate(13, $dark = $$value));
	let { numGuesses = 0 } = $$props;
	let { state = 'playing' } = $$props;
	let { challenge } = $$props;
	let b1;
	let b2;

	function generate() {
		const won = state === 'won';
		const symbols = [$dark ? '⬛' : '⬜'].concat($colourBlind ? ['🟦', '🟧'] : ['🟨', '🟩']);
		let ret = [];
		let one = [];

		for (const row of $gameProps.rows) {
			one = [];

			for (const cell of row.cluedLetters) {
				if (cell.clue !== undefined) {
					one.push(symbols[cell.clue]);
				}
			}

			ret.push(one.join(''));
		}

		return [
			`${challenge}`,
			`${won ? numGuesses : 'X'} / ${MAX_GUESSES}${('*').repeat($difficulty)}`,
			ret.join('\n').trim() + '\n'
		].join('\n\n');
	}

	function button_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			b1 = $$value;
			$$invalidate(2, b1);
		});
	}

	const click_handler = () => share(challenge, () => buttonHint(b1));

	function button_binding_1($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			b2 = $$value;
			$$invalidate(3, b2);
		});
	}

	const click_handler_1 = () => share(generate(), () => buttonHint(b2));

	$$self.$$set = $$props => {
		if ('numGuesses' in $$props) $$invalidate(5, numGuesses = $$props.numGuesses);
		if ('state' in $$props) $$invalidate(0, state = $$props.state);
		if ('challenge' in $$props) $$invalidate(1, challenge = $$props.challenge);
	};

	return [
		state,
		challenge,
		b1,
		b2,
		generate,
		numGuesses,
		button_binding,
		click_handler,
		button_binding_1,
		click_handler_1
	];
}

class Share extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { numGuesses: 5, state: 0, challenge: 1 });
	}
}

export default Share;