import './Row.svelte.css';
/* src/components/Row.svelte generated by Svelte v3.46.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { Clue, clueClass, clueWord } from '../clue.js';
import { wordLength } from '../store.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

// (10:2) {#each cluedLetters     .concat(Array($wordLength).fill({ clue: Clue.Absent, letter: '' }))     .slice(0, fake ? cluedLetters.length : $wordLength) as clue}
function create_each_block(ctx) {
	let td;
	let t_value = /*clue*/ ctx[5].letter + "";
	let t;
	let td_class_value;
	let td_aria_live_value;
	let td_aria_label_value;

	return {
		c() {
			td = element("td");
			t = text(t_value);

			attr(td, "class", td_class_value = "row-letter " + (/*rowState*/ ctx[0] === 'locked-in'
			? clueClass(/*clue*/ ctx[5].clue)
			: '') + " svelte-1a1udea");

			attr(td, "aria-live", td_aria_live_value = /*rowState*/ ctx[0] === 'editing' ? 'assertive' : 'off');

			attr(td, "aria-label", td_aria_label_value = /*rowState*/ ctx[0] === 'locked-in'
			? /*clue*/ ctx[5].letter.toUpperCase() + (/*clue*/ ctx[5].clue === undefined
				? ''
				: ': ' + clueWord(/*clue*/ ctx[5].clue))
			: '');
		},
		m(target, anchor) {
			insert(target, td, anchor);
			append(td, t);
		},
		p(ctx, dirty) {
			if (dirty & /*cluedLetters, $wordLength, fake*/ 22 && t_value !== (t_value = /*clue*/ ctx[5].letter + "")) set_data(t, t_value);

			if (dirty & /*rowState, cluedLetters, $wordLength, fake*/ 23 && td_class_value !== (td_class_value = "row-letter " + (/*rowState*/ ctx[0] === 'locked-in'
			? clueClass(/*clue*/ ctx[5].clue)
			: '') + " svelte-1a1udea")) {
				attr(td, "class", td_class_value);
			}

			if (dirty & /*rowState*/ 1 && td_aria_live_value !== (td_aria_live_value = /*rowState*/ ctx[0] === 'editing' ? 'assertive' : 'off')) {
				attr(td, "aria-live", td_aria_live_value);
			}

			if (dirty & /*rowState, cluedLetters, $wordLength, fake*/ 23 && td_aria_label_value !== (td_aria_label_value = /*rowState*/ ctx[0] === 'locked-in'
			? /*clue*/ ctx[5].letter.toUpperCase() + (/*clue*/ ctx[5].clue === undefined
				? ''
				: ': ' + clueWord(/*clue*/ ctx[5].clue))
			: '')) {
				attr(td, "aria-label", td_aria_label_value);
			}
		},
		d(detaching) {
			if (detaching) detach(td);
		}
	};
}

// (23:2) {#if annotation}
function create_if_block(ctx) {
	let span;
	let t;

	return {
		c() {
			span = element("span");
			t = text(/*annotation*/ ctx[3]);
			attr(span, "class", "annotation svelte-1a1udea");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty & /*annotation*/ 8) set_data(t, /*annotation*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function create_fragment(ctx) {
	let tr;
	let t;
	let tr_class_value;

	let each_value = /*cluedLetters*/ ctx[2].concat(Array(/*$wordLength*/ ctx[4]).fill({ clue: Clue.Absent, letter: '' })).slice(0, /*fake*/ ctx[1]
	? /*cluedLetters*/ ctx[2].length
	: /*$wordLength*/ ctx[4]);

	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block = /*annotation*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			tr = element("tr");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			if (if_block) if_block.c();

			attr(tr, "class", tr_class_value = "row " + (/*rowState*/ ctx[0] === 'locked-in'
			? 'row-locked-in'
			: '') + " svelte-1a1udea");
		},
		m(target, anchor) {
			insert(target, tr, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tr, null);
			}

			append(tr, t);
			if (if_block) if_block.m(tr, null);
		},
		p(ctx, [dirty]) {
			if (dirty & /*rowState, clueClass, cluedLetters, Array, $wordLength, Clue, fake, undefined, clueWord*/ 23) {
				each_value = /*cluedLetters*/ ctx[2].concat(Array(/*$wordLength*/ ctx[4]).fill({ clue: Clue.Absent, letter: '' })).slice(0, /*fake*/ ctx[1]
				? /*cluedLetters*/ ctx[2].length
				: /*$wordLength*/ ctx[4]);

				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(tr, t);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (/*annotation*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(tr, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*rowState*/ 1 && tr_class_value !== (tr_class_value = "row " + (/*rowState*/ ctx[0] === 'locked-in'
			? 'row-locked-in'
			: '') + " svelte-1a1udea")) {
				attr(tr, "class", tr_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(tr);
			destroy_each(each_blocks, detaching);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $wordLength;
	component_subscribe($$self, wordLength, $$value => $$invalidate(4, $wordLength = $$value));
	let { rowState } = $$props;
	let { fake = false } = $$props;
	let { cluedLetters = [] } = $$props;
	let { annotation = '' } = $$props;

	$$self.$$set = $$props => {
		if ('rowState' in $$props) $$invalidate(0, rowState = $$props.rowState);
		if ('fake' in $$props) $$invalidate(1, fake = $$props.fake);
		if ('cluedLetters' in $$props) $$invalidate(2, cluedLetters = $$props.cluedLetters);
		if ('annotation' in $$props) $$invalidate(3, annotation = $$props.annotation);
	};

	return [rowState, fake, cluedLetters, annotation, $wordLength];
}

class Row extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			rowState: 0,
			fake: 1,
			cluedLetters: 2,
			annotation: 3
		});
	}
}

export default Row;