import './Keyboard.svelte.css';
/* src/components/Keyboard.svelte generated by Svelte v3.46.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { clueClass } from '../clue.js';
import { gameProps, keyboard, leftEnter } from '../store.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[6] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	return child_ctx;
}

// (14:6) {#each row as label}
function create_each_block_1(ctx) {
	let button;
	let t_value = /*label*/ ctx[9].replace('Backspace', '⌫') + "";
	let t;
	let button_tabindex_value;
	let button_class_value;
	let mounted;
	let dispose;

	function click_handler() {
		return /*click_handler*/ ctx[5](/*label*/ ctx[9]);
	}

	return {
		c() {
			button = element("button");
			t = text(t_value);
			attr(button, "tabindex", button_tabindex_value = -1);

			attr(button, "class", button_class_value = "game-keyboard-button " + clueClass(/*$gameProps*/ ctx[2].letterInfo.get(/*label*/ ctx[9])) + " " + (/*label*/ ctx[9].length > 1
			? 'game-keyboard-button-wide'
			: '') + " svelte-1cxqhvo");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*layout*/ 2 && t_value !== (t_value = /*label*/ ctx[9].replace('Backspace', '⌫') + "")) set_data(t, t_value);

			if (dirty & /*$gameProps, layout*/ 6 && button_class_value !== (button_class_value = "game-keyboard-button " + clueClass(/*$gameProps*/ ctx[2].letterInfo.get(/*label*/ ctx[9])) + " " + (/*label*/ ctx[9].length > 1
			? 'game-keyboard-button-wide'
			: '') + " svelte-1cxqhvo")) {
				attr(button, "class", button_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (12:2) {#each layout as row}
function create_each_block(ctx) {
	let div;
	let t;
	let each_value_1 = /*row*/ ctx[6];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			attr(div, "class", "game-keyboard-row svelte-1cxqhvo");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*clueClass, $gameProps, layout, onKey*/ 7) {
				each_value_1 = /*row*/ ctx[6];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, t);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_value = /*layout*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "game-keyboard svelte-1cxqhvo");
			attr(div, "aria-hidden", "true");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*layout, clueClass, $gameProps, onKey*/ 7) {
				each_value = /*layout*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $leftEnter;
	let $keyboard;
	let $gameProps;
	component_subscribe($$self, leftEnter, $$value => $$invalidate(3, $leftEnter = $$value));
	component_subscribe($$self, keyboard, $$value => $$invalidate(4, $keyboard = $$value));
	component_subscribe($$self, gameProps, $$value => $$invalidate(2, $gameProps = $$value));
	let { onKey } = $$props;
	let layout;
	const click_handler = label => onKey(label);

	$$self.$$set = $$props => {
		if ('onKey' in $$props) $$invalidate(0, onKey = $$props.onKey);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$keyboard, $leftEnter*/ 24) {
			$: $$invalidate(1, layout = $keyboard.replaceAll(/[BE]/g, x => ($leftEnter ? 'EB' : 'BE')[('BE').indexOf(x)]).split('-').map(row => row.split('').map(key => key.replace('B', 'Backspace').replace('E', 'Enter'))));
		}
	};

	return [onKey, layout, $gameProps, $leftEnter, $keyboard, click_handler];
}

class Keyboard extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { onKey: 0 });
	}
}

export default Keyboard;