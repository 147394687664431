import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import './App.svelte.css';
/* src/App.svelte generated by Svelte v3.46.2 */
import {
	HtmlTag,
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { seed, urlParam } from './utils.js';
import About from './components/About.svelte.js';
import Game from './Game.svelte.js';
import { colourBlind, dark, difficulty, page } from './store.js';
import Settings from './components/Settings.svelte.js';

function create_else_block(ctx) {
	let button0;
	let t1;
	let t2_value = ' • ' + "";
	let t2;
	let t3;
	let button1;
	let mounted;
	let dispose;

	return {
		c() {
			button0 = element("button");
			button0.textContent = "❓";
			t1 = space();
			t2 = text(t2_value);
			t3 = space();
			button1 = element("button");
			button1.textContent = "⚙️";
			attr(button0, "class", "emoji-link svelte-bua5ar");
			attr(button0, "title", "Help");
			attr(button0, "aria-label", "Help");
			attr(button0, "id", "help");
			attr(button1, "class", "emoji-link svelte-bua5ar");
			attr(button1, "title", "Settings");
			attr(button1, "aria-label", "Settings");
			attr(button1, "id", "settings");
		},
		m(target, anchor) {
			insert(target, button0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
			insert(target, t3, anchor);
			insert(target, button1, anchor);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler_1*/ ctx[6]),
					listen(button1, "click", /*click_handler_2*/ ctx[7])
				];

				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
			if (detaching) detach(t3);
			if (detaching) detach(button1);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (40:4) {#if $page !== 'game'}
function create_if_block_3(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "❌";
			attr(button, "class", "emoji-link svelte-bua5ar");
			attr(button, "aria-label", "Close");
			attr(button, "id", "game");
			attr(button, "title", "Close");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (81:2) {#if $page === 'about'}
function create_if_block_2(ctx) {
	let about;
	let current;
	about = new About({});

	return {
		c() {
			create_component(about.$$.fragment);
		},
		m(target, anchor) {
			mount_component(about, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(about.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(about.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(about, detaching);
		}
	};
}

// (84:2) {#if $page === 'settings'}
function create_if_block_1(ctx) {
	let settings;
	let current;
	settings = new Settings({});

	return {
		c() {
			create_component(settings.$$.fragment);
		},
		m(target, anchor) {
			mount_component(settings, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(settings.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(settings.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(settings, detaching);
		}
	};
}

// (89:4) {#if version !== undefined}
function create_if_block(ctx) {
	let p;
	let t0;
	let html_tag;
	let t1;

	return {
		c() {
			p = element("p");
			t0 = text("Running on ");
			html_tag = new HtmlTag();
			t1 = text(".");
			html_tag.a = t1;
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t0);
			html_tag.m(/*version*/ ctx[0], p);
			append(p, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*version*/ 1) html_tag.p(/*version*/ ctx[0]);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let h1;
	let t0;
	let t1_value = ('*').repeat(/*$difficulty*/ ctx[2]) + "";
	let t1;
	let t2;
	let div0;
	let t3;
	let div1;
	let a0;
	let t4_value = (seed ? 'Random' : "Today's") + "";
	let t4;
	let a0_href_value;
	let div1_class_value;
	let t5;
	let t6;
	let t7;
	let game;
	let t8;
	let footer;
	let t9;
	let p0;
	let t11;
	let p1;
	let div2_class_value;
	let current;

	function select_block_type(ctx, dirty) {
		if (/*$page*/ ctx[3] !== 'game') return create_if_block_3;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);
	let if_block1 = /*$page*/ ctx[3] === 'about' && create_if_block_2(ctx);
	let if_block2 = /*$page*/ ctx[3] === 'settings' && create_if_block_1(ctx);
	game = new Game({});
	let if_block3 = /*version*/ ctx[0] !== undefined && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			h1 = element("h1");
			t0 = text("Wordle clone");
			t1 = text(t1_value);
			t2 = space();
			div0 = element("div");
			if_block0.c();
			t3 = space();
			div1 = element("div");
			a0 = element("a");
			t4 = text(t4_value);
			t5 = space();
			if (if_block1) if_block1.c();
			t6 = space();
			if (if_block2) if_block2.c();
			t7 = space();
			create_component(game.$$.fragment);
			t8 = space();
			footer = element("footer");
			if (if_block3) if_block3.c();
			t9 = space();
			p0 = element("p");
			p0.innerHTML = `<a href="https://git.varunj.me/darthshittious/better-wordle">View Source</a>`;
			t11 = space();
			p1 = element("p");
			p1.textContent = "Report weird words or other issues via email. My email is not hard to find.";
			attr(h1, "class", "svelte-bua5ar");
			attr(div0, "class", "abs-l5-t5 svelte-bua5ar");
			attr(a0, "href", a0_href_value = seed ? '?random' : `?seed=${/*todaySeed*/ ctx[4]()}`);
			attr(div1, "class", div1_class_value = "abs-r5-t5 " + (/*$page*/ ctx[3] === 'game' ? 'visible' : 'hidden') + " svelte-bua5ar");
			attr(footer, "class", "app-footer svelte-bua5ar");
			attr(div2, "class", div2_class_value = "app-container " + (/*$colourBlind*/ ctx[1] ? 'colour-blind' : '') + " svelte-bua5ar");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, h1);
			append(h1, t0);
			append(h1, t1);
			append(div2, t2);
			append(div2, div0);
			if_block0.m(div0, null);
			append(div2, t3);
			append(div2, div1);
			append(div1, a0);
			append(a0, t4);
			append(div2, t5);
			if (if_block1) if_block1.m(div2, null);
			append(div2, t6);
			if (if_block2) if_block2.m(div2, null);
			append(div2, t7);
			mount_component(game, div2, null);
			append(div2, t8);
			append(div2, footer);
			if (if_block3) if_block3.m(footer, null);
			append(footer, t9);
			append(footer, p0);
			append(footer, t11);
			append(footer, p1);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*$difficulty*/ 4) && t1_value !== (t1_value = ('*').repeat(/*$difficulty*/ ctx[2]) + "")) set_data(t1, t1_value);

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div0, null);
				}
			}

			if (!current || dirty & /*$page*/ 8 && div1_class_value !== (div1_class_value = "abs-r5-t5 " + (/*$page*/ ctx[3] === 'game' ? 'visible' : 'hidden') + " svelte-bua5ar")) {
				attr(div1, "class", div1_class_value);
			}

			if (/*$page*/ ctx[3] === 'about') {
				if (if_block1) {
					if (dirty & /*$page*/ 8) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div2, t6);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*$page*/ ctx[3] === 'settings') {
				if (if_block2) {
					if (dirty & /*$page*/ 8) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div2, t7);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*version*/ ctx[0] !== undefined) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block(ctx);
					if_block3.c();
					if_block3.m(footer, t9);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (!current || dirty & /*$colourBlind*/ 2 && div2_class_value !== (div2_class_value = "app-container " + (/*$colourBlind*/ ctx[1] ? 'colour-blind' : '') + " svelte-bua5ar")) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(game.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(game.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			destroy_component(game);
			if (if_block3) if_block3.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $dark;
	let $colourBlind;
	let $difficulty;
	let $page;
	component_subscribe($$self, dark, $$value => $$invalidate(8, $dark = $$value));
	component_subscribe($$self, colourBlind, $$value => $$invalidate(1, $colourBlind = $$value));
	component_subscribe($$self, difficulty, $$value => $$invalidate(2, $difficulty = $$value));
	component_subscribe($$self, page, $$value => $$invalidate(3, $page = $$value));
	let version;
	const commit = __SNOWPACK_ENV__.commit;

	function getVersion() {
		if (commit && commit.hash === 'dev') {
			return 'a development version';
		} else if (commit) {
			const url = `https://git.varunj.me/darthshittious/better-wordle/src/commit/${commit.hash}`;
			const title = (commit.message ?? '').split('\n')[0];
			return `version <code><a href="${url}" title="${title}">${commit.hash}</a></code>`;
		} else {
			return 'an unknown version';
		}
	}

	version = getVersion();

	const todaySeed = () => {
		const date = new Date();
		const pad = num => (num < 10 ? '0' : '') + num;
		return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}`;
	};

	document.body.className = $dark ? 'dark' : '';

	if (urlParam('today') !== null || urlParam('todas') !== null) {
		document.location = `?seed=${todaySeed()}`;
	}

	setTimeout(
		() => {
			// avoid transition on page load
			document.body.style.transition = '0.3s background-color ease-out';
		},
		1
	);

	const click_handler = () => {
		set_store_value(page, $page = 'game', $page);
	};

	const click_handler_1 = () => {
		set_store_value(page, $page = 'about', $page);
	};

	const click_handler_2 = () => {
		set_store_value(page, $page = 'settings', $page);
	};

	return [
		version,
		$colourBlind,
		$difficulty,
		$page,
		todaySeed,
		click_handler,
		click_handler_1,
		click_handler_2
	];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;