import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

const key = __SNOWPACK_ENV__.key;
function offset(letter) {
  return letter.toLowerCase().charCodeAt(0) - 97;
}
function shift(letter, _shift, forward) {
  _shift = forward ? _shift : -_shift;
  const charCode = letter.toLowerCase().charCodeAt(0);
  return 97 + (charCode + _shift - 97 + 26) % 26;
}
function cipher(text, key2, encrypt) {
  const keyArray = key2.split("").map((c) => offset(c));
  const letters = [];
  for (let i = 0; i < text.length; i++) {
    letters.push(shift(text[i], keyArray[i % keyArray.length], encrypt));
  }
  return String.fromCharCode(...letters);
}
window.cipher = window.cipher || cipher;
export function encode(text) {
  return window.btoa(cipher(text, key, true)).replace(/\//g, "_").replace(/\+/g, "-").replace(/=*$/, "");
}
export function decode(text) {
  return cipher(window.atob(text.replace(/_/g, "/").replace(/-/g, "+")), key, false);
}
