import dictionary from "./dictionary.js";
export const dictionarySet = new Set(dictionary);
export const MAX_GUESSES = 6;
export const MIN_LENGTH = 4;
export const MAX_LENGTH = 11;
export const DEFAULT_LENGTH = 5;
function mulberry32(a) {
  return function() {
    let t = a += 1831565813;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  };
}
export function urlParam(name) {
  return new URLSearchParams(window.location.search).get(name);
}
export const seed = Number(urlParam("seed"));
const makeRandom = () => seed ? mulberry32(seed) : () => Math.random();
let random = makeRandom();
export function resetRng() {
  random = makeRandom();
}
export function pick(array) {
  return array[Math.floor(random() * array.length)];
}
export function speak(text, priority = "assertive") {
  const el = document.createElement("div");
  const id = "speak-" + Date.now();
  el.setAttribute("id", id);
  el.setAttribute("aria-live", priority || "polite");
  el.classList.add("sr-only");
  document.body.appendChild(el);
  window.setTimeout(function() {
    document.getElementById(id).innerHTML = text;
  }, 100);
  window.setTimeout(function() {
    document.body.removeChild(document.getElementById(id));
  }, 1e3);
}
export function ordinal(n) {
  return `${n}${[null, "st", "nd", "rd"][n % 100 >> 3 ^ 1 && n % 10] || "th"}`;
}
export function useSetting(key, initial) {
  let current;
  try {
    const item = localStorage.getItem(key);
    current = item ? JSON.parse(item) : initial;
  } catch (e) {
    current = initial;
  }
  return current;
}
export function setSetting(key, value, current) {
  try {
    const v = value instanceof Function ? value(current) : value;
    localStorage.setItem(key, JSON.stringify(v));
    return v;
  } catch (e) {
    console.warn(e);
    return current;
  }
}
export const englishNumbers = "zero one two three four five six seven eight nine ten eleven".split(" ");
export function describeSeed(seed2) {
  const year = Math.floor(seed2 / 1e4);
  const month = Math.floor(seed2 / 100) % 100;
  const day = seed2 % 100;
  const isLeap = year % (year % 25 ? 4 : 16) === 0;
  const feb = isLeap ? 29 : 28;
  const days = [0, 31, feb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year >= 2e3 && year <= 2100 && month >= 1 && month <= 12 && day >= 1 && day <= days[month]) {
    return new Date(year, month - 1, day).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric"
    });
  } else {
    return `seed ${seed2}`;
  }
}
