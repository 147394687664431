import {englishNumbers, ordinal} from "./utils.js";
export var Clue;
(function(Clue2) {
  Clue2[Clue2["Absent"] = 0] = "Absent";
  Clue2[Clue2["Elsewhere"] = 1] = "Elsewhere";
  Clue2[Clue2["Correct"] = 2] = "Correct";
})(Clue || (Clue = {}));
export function clue(word, target) {
  const elusive = [];
  target.split("").forEach((letter, i) => {
    if (word[i] !== letter) {
      elusive.push(letter);
    }
  });
  return word.split("").map((letter, i) => {
    let j;
    if (target[i] === letter) {
      return {clue: 2, letter};
    } else if ((j = elusive.indexOf(letter)) > -1) {
      elusive[j] = "";
      return {clue: 1, letter};
    } else {
      return {clue: 0, letter};
    }
  });
}
export function clueClass(clue2) {
  if (clue2 === void 0)
    return "";
  return `letter-${clue2 === 2 ? "correct" : clue2 === 1 ? "elsewhere" : "absent"}`;
}
export function clueWord(clue2) {
  if (clue2 === 0) {
    return "no";
  } else if (clue2 === 1) {
    return "elsewhere";
  } else {
    return "correct";
  }
}
export function describeClue(clue2) {
  return clue2.map(({letter, clue: clue3}) => letter.toUpperCase() + " " + clueWord(clue3)).join(", ");
}
export function violation(difficulty, clues, guess) {
  if (difficulty === 0) {
    return void 0;
  }
  const insane = difficulty === 2;
  let i = 0;
  for (const {letter, clue: clue2} of clues) {
    const clueCount = clues.filter((c) => c.letter === letter && c.clue !== 0).length;
    const guessCount = guess.split(letter).length - 1;
    const glyph = letter.toUpperCase();
    const glyphs = glyph + (clueCount !== 1 ? "s" : "");
    const nth = ordinal(i + 1);
    if (clue2 === 2 && guess[i] !== letter) {
      return `${nth} letter must be ${glyph}`;
    }
    if (guessCount < clueCount) {
      const atLeast = clueCount > 1 ? `at least ${englishNumbers[clueCount]} ` : "";
      return `Guess must contain ${atLeast}${glyphs}`;
    }
    if (insane && clue2 !== 2 && guess[i] === letter) {
      return `${nth} letter can't be ${glyph}`;
    }
    if (insane && clue2 === 0 && guessCount !== clueCount) {
      return clueCount === 0 ? `Guess can't contain ${glyph}` : `Guess must contain exactly ${englishNumbers[clueCount]} ${glyphs}`;
    }
    ++i;
  }
  return void 0;
}
