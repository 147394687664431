import {writable} from "../_snowpack/pkg/svelte/store.js";
import {MAX_GUESSES, useSetting} from "./utils.js";
export const wordLength = writable(5);
export const gameProps = writable({
  rows: Array(MAX_GUESSES).fill({rowState: "pending", cluedLetters: []}),
  letterInfo: new Map()
});
export const page = writable("game");
const prefersDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
export const dark = writable(useSetting("dark", prefersDark));
export const difficulty = writable(useSetting("difficulty", 0));
export const colourBlind = writable(useSetting("colourBlind", false));
export const keyboard = writable(useSetting("keyboard", "qwertyuiop-asdfghjkl-BzxcvbnmE"));
export const leftEnter = writable(useSetting("leftEnter", false));
