import './Game.svelte.css';
/* src/Game.svelte generated by Svelte v3.46.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import {
	dictionarySet,
	describeSeed,
	pick,
	MAX_GUESSES,
	MIN_LENGTH,
	MAX_LENGTH,
	resetRng,
	seed,
	speak,
	urlParam,
	DEFAULT_LENGTH
} from './utils.js';

import { encode, decode } from './cipher.js';
import { targets as targetList } from './targets.js';
import dictionary from './dictionary.js';
import { clue, describeClue, violation } from './clue.js';
import { wordLength, gameProps, page, difficulty } from './store.js';
import Keyboard from './components/Keyboard.svelte.js';
import Row from './components/Row.svelte.js';
import Share from './components/Share.svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[27] = list[i];
	child_ctx[29] = i;
	return child_ctx;
}

// (212:6) {#each Array(MAX_GUESSES) as _, i}
function create_each_block(ctx) {
	let row;
	let current;

	row = new Row({
			props: {
				rowState: /*$gameProps*/ ctx[8].rows[/*i*/ ctx[29]].rowState,
				cluedLetters: /*$gameProps*/ ctx[8].rows[/*i*/ ctx[29]].cluedLetters
			}
		});

	return {
		c() {
			create_component(row.$$.fragment);
		},
		m(target, anchor) {
			mount_component(row, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const row_changes = {};
			if (dirty & /*$gameProps*/ 256) row_changes.rowState = /*$gameProps*/ ctx[8].rows[/*i*/ ctx[29]].rowState;
			if (dirty & /*$gameProps*/ 256) row_changes.cluedLetters = /*$gameProps*/ ctx[8].rows[/*i*/ ctx[29]].cluedLetters;
			row.$set(row_changes);
		},
		i(local) {
			if (current) return;
			transition_in(row.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(row.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(row, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let label;
	let t1;
	let input;
	let input_disabled_value;
	let t2;
	let button;
	let t3;
	let button_disabled_value;
	let t4;
	let table;
	let tbody;
	let table_tabindex_value;
	let t5;
	let p0;
	let t6_value = (/*hint*/ ctx[5] || `\u00a0`) + "";
	let t6;
	let t7;
	let keyboard;
	let t8;
	let div1;

	let t9_value = (/*challenge*/ ctx[1]
	? 'playing a challenge game'
	: seed
		? `${describeSeed(seed)} — length ${/*$wordLength*/ ctx[9]}, game ${/*gameNumber*/ ctx[0]}`
		: 'playing a random game') + "";

	let t9;
	let t10;
	let p1;
	let share;
	let current;
	let mounted;
	let dispose;
	let each_value = Array(MAX_GUESSES);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	keyboard = new Keyboard({ props: { onKey: /*onKey*/ ctx[13] } });

	share = new Share({
			props: {
				state: /*gameState*/ ctx[3],
				numGuesses: /*guesses*/ ctx[7].length,
				challenge: seed
				? window.location.origin + window.location.pathname + /*currentSeedParams*/ ctx[12]()
				: /*getChallengeUrl*/ ctx[11](/*target*/ ctx[4])
			}
		});

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			label = element("label");
			label.textContent = "Letters:";
			t1 = space();
			input = element("input");
			t2 = space();
			button = element("button");
			t3 = text("Give up");
			t4 = space();
			table = element("table");
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t5 = space();
			p0 = element("p");
			t6 = text(t6_value);
			t7 = space();
			create_component(keyboard.$$.fragment);
			t8 = space();
			div1 = element("div");
			t9 = text(t9_value);
			t10 = space();
			p1 = element("p");
			create_component(share.$$.fragment);
			attr(label, "for", "word-length");
			attr(label, "class", "svelte-1neh497");
			attr(input, "type", "range");
			attr(input, "min", MIN_LENGTH);
			attr(input, "max", MAX_LENGTH);
			attr(input, "id", "word-length");
			input.disabled = input_disabled_value = /*gameState*/ ctx[3] === 'playing' && (/*guesses*/ ctx[7].length > 0 || /*currentGuess*/ ctx[6] !== '' || /*challenge*/ ctx[1] !== '');
			input.value = /*$wordLength*/ ctx[9];
			attr(input, "class", "svelte-1neh497");
			set_style(button, "flex", "0 0 auto");
			button.disabled = button_disabled_value = /*gameState*/ ctx[3] !== 'playing' || /*guesses*/ ctx[7].length === 0;
			attr(button, "class", "svelte-1neh497");
			attr(div0, "class", "game-options svelte-1neh497");
			attr(tbody, "class", "svelte-1neh497");
			attr(table, "class", "game-rows svelte-1neh497");
			attr(table, "tabindex", table_tabindex_value = 0);
			attr(table, "aria-label", "Table of guesses");
			attr(p0, "role", "alert");
			attr(div1, "class", "game-seed-info svelte-1neh497");
			attr(div2, "class", "game svelte-1neh497");
			set_style(div2, "display", /*$page*/ ctx[10] !== 'game' ? 'none' : 'block');
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, label);
			append(div0, t1);
			append(div0, input);
			append(div0, t2);
			append(div0, button);
			append(button, t3);
			append(div2, t4);
			append(div2, table);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}

			/*table_binding*/ ctx[16](table);
			append(div2, t5);
			append(div2, p0);
			append(p0, t6);
			append(div2, t7);
			mount_component(keyboard, div2, null);
			append(div2, t8);
			append(div2, div1);
			append(div1, t9);
			append(div2, t10);
			append(div2, p1);
			mount_component(share, p1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(input, "change", /*updateLength*/ ctx[14]),
					listen(button, "click", /*bail*/ ctx[15])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*gameState, guesses, currentGuess, challenge*/ 202 && input_disabled_value !== (input_disabled_value = /*gameState*/ ctx[3] === 'playing' && (/*guesses*/ ctx[7].length > 0 || /*currentGuess*/ ctx[6] !== '' || /*challenge*/ ctx[1] !== ''))) {
				input.disabled = input_disabled_value;
			}

			if (!current || dirty & /*$wordLength*/ 512) {
				input.value = /*$wordLength*/ ctx[9];
			}

			if (!current || dirty & /*gameState, guesses*/ 136 && button_disabled_value !== (button_disabled_value = /*gameState*/ ctx[3] !== 'playing' || /*guesses*/ ctx[7].length === 0)) {
				button.disabled = button_disabled_value;
			}

			if (dirty & /*$gameProps*/ 256) {
				each_value = Array(MAX_GUESSES);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(tbody, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if ((!current || dirty & /*hint*/ 32) && t6_value !== (t6_value = (/*hint*/ ctx[5] || `\u00a0`) + "")) set_data(t6, t6_value);

			if ((!current || dirty & /*challenge, $wordLength, gameNumber*/ 515) && t9_value !== (t9_value = (/*challenge*/ ctx[1]
			? 'playing a challenge game'
			: seed
				? `${describeSeed(seed)} — length ${/*$wordLength*/ ctx[9]}, game ${/*gameNumber*/ ctx[0]}`
				: 'playing a random game') + "")) set_data(t9, t9_value);

			const share_changes = {};
			if (dirty & /*gameState*/ 8) share_changes.state = /*gameState*/ ctx[3];
			if (dirty & /*guesses*/ 128) share_changes.numGuesses = /*guesses*/ ctx[7].length;

			if (dirty & /*target*/ 16) share_changes.challenge = seed
			? window.location.origin + window.location.pathname + /*currentSeedParams*/ ctx[12]()
			: /*getChallengeUrl*/ ctx[11](/*target*/ ctx[4]);

			share.$set(share_changes);

			if (!current || dirty & /*$page*/ 1024) {
				set_style(div2, "display", /*$page*/ ctx[10] !== 'game' ? 'none' : 'block');
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(keyboard.$$.fragment, local);
			transition_in(share.$$.fragment, local);
			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(keyboard.$$.fragment, local);
			transition_out(share.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_each(each_blocks, detaching);
			/*table_binding*/ ctx[16](null);
			destroy_component(keyboard);
			destroy_component(share);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $gameProps;
	let $wordLength;
	let $difficulty;
	let $page;
	component_subscribe($$self, gameProps, $$value => $$invalidate(8, $gameProps = $$value));
	component_subscribe($$self, wordLength, $$value => $$invalidate(9, $wordLength = $$value));
	component_subscribe($$self, difficulty, $$value => $$invalidate(18, $difficulty = $$value));
	component_subscribe($$self, page, $$value => $$invalidate(10, $page = $$value));
	const targets = targetList.slice(0, 20000).filter(word => dictionarySet.has(word));
	const limitLength = n => n >= MIN_LENGTH && n <= MAX_LENGTH ? n : DEFAULT_LENGTH;

	function randomTarget(len) {
		const eligible = targets.filter(word => word.length === len);
		return pick(eligible);
	}

	function getChallengeUrl(target) {
		return `${window.location.origin}${window.location.pathname}?challenge=${encode(target)}`;
	}

	let challenge = '';
	let challengeError = false;

	try {
		challenge = decode(urlParam('challenge') ?? '').toLowerCase();
	} catch(e) {
		console.warn(e);
		challengeError = true;
	}

	if (challenge && !dictionarySet.has(challenge)) {
		challenge = '';
		challengeError = true;
	}

	function parseUrlLength() {
		const lengthParam = urlParam('length');
		if (!lengthParam) return DEFAULT_LENGTH;
		return limitLength(Number(lengthParam));
	}

	function parseUrlGameNumber() {
		const gameParam = urlParam('game');
		if (!gameParam) return 1;
		const gameNumber = Number(gameParam);
		return gameNumber >= 1 && gameNumber <= 1000 ? gameNumber : 1;
	}

	let gameNumber = parseUrlGameNumber();
	let tableRef;
	set_store_value(wordLength, $wordLength = challenge ? challenge.length : parseUrlLength(), $wordLength);
	let gameState = 'playing';
	resetRng();

	// skip RNG ahead to the parsed initial game number.
	for (let i = 1; i < gameNumber; i++) randomTarget($wordLength);

	let target = challenge || randomTarget($wordLength);

	let hint = challengeError
	? 'Invalid challenge string, playing random game.'
	: 'Make your first guess!';

	const currentSeedParams = () => `?seed=${seed}&length=${$wordLength}&game=${gameNumber}`;
	let currentGuess = '';
	let guesses = [];

	const startNextGame = () => {
		if (challenge) {
			window.history.replaceState({}, document.title, window.location.pathname);

			// just in case someone reverse engineered the challenge string and put in a word
			// longer or shorter than allowed normally
			set_store_value(wordLength, $wordLength = limitLength($wordLength), $wordLength);
		}

		$$invalidate(1, challenge = '');
		$$invalidate(4, target = randomTarget($wordLength));
		$$invalidate(7, guesses = []);
		$$invalidate(6, currentGuess = '');
		$$invalidate(5, hint = '');
		$$invalidate(3, gameState = 'playing');
		$$invalidate(0, gameNumber += 1);
		$gameProps.letterInfo.clear();

		for (let i = 0; i < MAX_GUESSES; i++) {
			renderBoard(i);
		}
	};

	const onKey = key => {
		if (gameState !== 'playing') {
			if (key === 'Enter') {
				startNextGame();
			}

			return;
		}

		if (guesses.length === MAX_GUESSES) return;

		if ((/^[a-zA-Z]$/).test(key)) {
			$$invalidate(6, currentGuess = (currentGuess + key.toLowerCase()).slice(0, $wordLength));
			tableRef?.focus();
			$$invalidate(5, hint = '');
		} else if (key === 'Backspace') {
			$$invalidate(6, currentGuess = currentGuess.slice(0, -1));
			$$invalidate(5, hint = '');
		} else if (key === 'Enter') {
			if (currentGuess.length !== $wordLength) {
				$$invalidate(5, hint = 'Too short');
				return;
			}

			if (!dictionary.includes(currentGuess)) {
				$$invalidate(5, hint = 'not a valid word');
				return;
			}

			for (const g of guesses) {
				const feedback = violation($difficulty, clue(g, target), currentGuess);

				if (feedback) {
					$$invalidate(5, hint = feedback);
					return;
				}
			}

			$$invalidate(7, guesses = guesses.concat([currentGuess]));

			const gameOver = outcome => {
				return `You ${outcome}! The answer was ${target.toUpperCase()}. (Enter to ${challenge ? 'play a random game' : 'play again'})`;
			};

			if (currentGuess === target) {
				$$invalidate(5, hint = gameOver('won'));
				$$invalidate(3, gameState = 'won');
			} else if (guesses.length === MAX_GUESSES) {
				$$invalidate(5, hint = gameOver('lost'));
				$$invalidate(3, gameState = 'lost');
			} else {
				$$invalidate(5, hint = '');
				speak(describeClue(clue(currentGuess, target)));
			}

			$$invalidate(6, currentGuess = '');
		}

		for (let x = 0; x < MAX_GUESSES; x++) {
			renderBoard(x);
		}
	};

	const onKeydown = e => {
		if (!e.ctrlKey && !e.metaKey) {
			onKey(e.key);
		}

		if (e.key === 'Backspace') {
			e.preventDefault();
		}
	};

	function renderBoard(i) {
		const guess = [...guesses, currentGuess][i] ?? '';
		const cluedLetters = clue(guess, target);
		const lockedIn = i < guesses.length;

		if (lockedIn) {
			for (const { clue, letter } of cluedLetters) {
				if (clue === undefined) break;
				const old = $gameProps.letterInfo.get(letter);

				if (old === undefined || clue > old) {
					$gameProps.letterInfo.set(letter, clue);
				}
			}
		}

		set_store_value(
			gameProps,
			$gameProps.rows[i] = {
				rowState: lockedIn
				? 'locked-in'
				: i === guesses.length ? 'editing' : 'pending',
				cluedLetters
			},
			$gameProps
		);
	}

	const updateLength = e => {
		const len = Number(e.target.value);
		resetRng();
		$$invalidate(0, gameNumber = 1);
		$$invalidate(3, gameState = 'playing');
		$$invalidate(7, guesses = []);
		$$invalidate(6, currentGuess = '');
		$$invalidate(4, target = randomTarget(len));
		set_store_value(wordLength, $wordLength = len, $wordLength);
		$$invalidate(5, hint = `${len} letters`);
		$gameProps.letterInfo.clear();

		for (let i = 0; i < MAX_GUESSES; i++) {
			renderBoard(i);
		}
	};

	const bail = () => {
		$$invalidate(5, hint = `The answer was ${target.toUpperCase()}. (Enter to play again)`);
		$$invalidate(3, gameState = 'lost');
		document.activeElement?.blur();
	};

	window.addEventListener('keydown', onKeydown);

	function table_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			tableRef = $$value;
			$$invalidate(2, tableRef);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*gameNumber*/ 1) {
			$: if (seed) {
				$$invalidate(0, gameNumber);
				window.history.replaceState({}, document.title, window.location.pathname + currentSeedParams());
			}
		}
	};

	return [
		gameNumber,
		challenge,
		tableRef,
		gameState,
		target,
		hint,
		currentGuess,
		guesses,
		$gameProps,
		$wordLength,
		$page,
		getChallengeUrl,
		currentSeedParams,
		onKey,
		updateLength,
		bail,
		table_binding
	];
}

class Game extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Game;