import './Settings.svelte.css';
/* src/components/Settings.svelte generated by Svelte v3.46.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	select_option,
	set_data,
	set_store_value,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { setSetting } from '../utils.js';
import { colourBlind, dark, difficulty, keyboard, leftEnter } from '../store.js';

function create_fragment(ctx) {
	let div6;
	let div0;
	let input0;
	let t0;
	let label0;
	let t2;
	let div1;
	let input1;
	let t3;
	let label1;
	let t5;
	let div4;
	let input2;
	let t6;
	let div3;
	let label2;
	let t8;
	let strong;
	let t9_value = ['Normal', 'Hard', 'Ultra Hard'][/*$difficulty*/ ctx[2]] + "";
	let t9;
	let t10;
	let div2;

	let t11_value = [
		'Guesses must be valid dictionary words.',
		`Wordle's "Hard Mode". Green letters must stay fixed, and yellow letters must be reused.`,
		'An even stricter Hard Mode. Yellow letters must move away from where they were, and grey clues must be obeyed.'
	][/*$difficulty*/ ctx[2]] + "";

	let t11;
	let t12;
	let div5;
	let label3;
	let t14;
	let select;
	let option0;
	let option1;
	let option2;
	let option3;
	let option4;
	let t20;
	let input3;
	let t21;
	let label4;
	let mounted;
	let dispose;

	return {
		c() {
			div6 = element("div");
			div0 = element("div");
			input0 = element("input");
			t0 = space();
			label0 = element("label");
			label0.textContent = "Dark theme";
			t2 = space();
			div1 = element("div");
			input1 = element("input");
			t3 = space();
			label1 = element("label");
			label1.textContent = "High-contrast colours";
			t5 = space();
			div4 = element("div");
			input2 = element("input");
			t6 = space();
			div3 = element("div");
			label2 = element("label");
			label2.textContent = "Difficulty:";
			t8 = space();
			strong = element("strong");
			t9 = text(t9_value);
			t10 = space();
			div2 = element("div");
			t11 = text(t11_value);
			t12 = space();
			div5 = element("div");
			label3 = element("label");
			label3.textContent = "Keyboard layout:";
			t14 = space();
			select = element("select");
			option0 = element("option");
			option0.textContent = "QWERTY";
			option1 = element("option");
			option1.textContent = "AZERTY";
			option2 = element("option");
			option2.textContent = "QWERTZ";
			option3 = element("option");
			option3.textContent = "Dvorak";
			option4 = element("option");
			option4.textContent = "Colemak";
			t20 = space();
			input3 = element("input");
			t21 = space();
			label4 = element("label");
			label4.textContent = "\"Enter\" on left side";
			attr(input0, "type", "checkbox");
			attr(input0, "id", "set-dark");
			input0.checked = /*$dark*/ ctx[4];
			attr(input0, "class", "svelte-1p0mrf1");
			attr(label0, "for", "set-dark");
			attr(label0, "class", "svelte-1p0mrf1");
			attr(div0, "class", "setting svelte-1p0mrf1");
			attr(input1, "type", "checkbox");
			attr(input1, "id", "set-colourblind");
			input1.checked = /*$colourBlind*/ ctx[3];
			attr(input1, "class", "svelte-1p0mrf1");
			attr(label1, "for", "set-colourblind");
			attr(label1, "class", "svelte-1p0mrf1");
			attr(div1, "class", "setting svelte-1p0mrf1");
			attr(input2, "type", "range");
			attr(input2, "id", "set-difficulty");
			attr(input2, "min", "0");
			attr(input2, "max", "2");
			input2.value = /*$difficulty*/ ctx[2];
			attr(input2, "class", "svelte-1p0mrf1");
			attr(label2, "for", "set-difficulty");
			attr(label2, "class", "svelte-1p0mrf1");
			attr(div2, "class", "difficulty-desc svelte-1p0mrf1");
			attr(div4, "class", "setting svelte-1p0mrf1");
			attr(label3, "for", "set-keyboard");
			attr(label3, "class", "svelte-1p0mrf1");
			option0.__value = "qwertyuiop-asdfghjkl-BzxcvbnmE";
			option0.value = option0.__value;
			option1.__value = "azertyuiop-qsdfghjklm-BwxcvbnE";
			option1.value = option1.__value;
			option2.__value = "qwertzuiop-asdfghjkl-ByxcvbnmE";
			option2.value = option2.__value;
			option3.__value = "BpyfgcrlE-aoeuidhtns-qjkxbmwvz";
			option3.value = option3.__value;
			option4.__value = "qwfpgjluy-arstdhneio-BzxcvbkmE";
			option4.value = option4.__value;
			attr(select, "name", "keyboard-layout");
			attr(select, "id", "set-keyboard");
			attr(input3, "class", "ml-20 svelte-1p0mrf1");
			attr(input3, "id", "set-left-enter");
			attr(input3, "type", "checkbox");
			input3.checked = /*$leftEnter*/ ctx[0];
			attr(label4, "for", "set-left-enter");
			attr(label4, "class", "svelte-1p0mrf1");
			attr(div5, "class", "setting svelte-1p0mrf1");
			attr(div6, "class", "settings svelte-1p0mrf1");
		},
		m(target, anchor) {
			insert(target, div6, anchor);
			append(div6, div0);
			append(div0, input0);
			append(div0, t0);
			append(div0, label0);
			append(div6, t2);
			append(div6, div1);
			append(div1, input1);
			append(div1, t3);
			append(div1, label1);
			append(div6, t5);
			append(div6, div4);
			append(div4, input2);
			append(div4, t6);
			append(div4, div3);
			append(div3, label2);
			append(div3, t8);
			append(div3, strong);
			append(strong, t9);
			append(div3, t10);
			append(div3, div2);
			append(div2, t11);
			append(div6, t12);
			append(div6, div5);
			append(div5, label3);
			append(div5, t14);
			append(div5, select);
			append(select, option0);
			append(select, option1);
			append(select, option2);
			append(select, option3);
			append(select, option4);
			select_option(select, /*$keyboard*/ ctx[1]);
			append(div5, t20);
			append(div5, input3);
			append(div5, t21);
			append(div5, label4);

			if (!mounted) {
				dispose = [
					listen(input0, "change", /*toggleDark*/ ctx[5]),
					listen(input1, "change", /*toggleCb*/ ctx[6]),
					listen(input2, "change", /*updateDifficulty*/ ctx[7]),
					listen(select, "change", /*updateKeyboard*/ ctx[8]),
					listen(input3, "change", /*toggleLeftEnter*/ ctx[9])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*$dark*/ 16) {
				input0.checked = /*$dark*/ ctx[4];
			}

			if (dirty & /*$colourBlind*/ 8) {
				input1.checked = /*$colourBlind*/ ctx[3];
			}

			if (dirty & /*$difficulty*/ 4) {
				input2.value = /*$difficulty*/ ctx[2];
			}

			if (dirty & /*$difficulty*/ 4 && t9_value !== (t9_value = ['Normal', 'Hard', 'Ultra Hard'][/*$difficulty*/ ctx[2]] + "")) set_data(t9, t9_value);

			if (dirty & /*$difficulty*/ 4 && t11_value !== (t11_value = [
				'Guesses must be valid dictionary words.',
				`Wordle's "Hard Mode". Green letters must stay fixed, and yellow letters must be reused.`,
				'An even stricter Hard Mode. Yellow letters must move away from where they were, and grey clues must be obeyed.'
			][/*$difficulty*/ ctx[2]] + "")) set_data(t11, t11_value);

			if (dirty & /*$keyboard*/ 2) {
				select_option(select, /*$keyboard*/ ctx[1]);
			}

			if (dirty & /*$leftEnter*/ 1) {
				input3.checked = /*$leftEnter*/ ctx[0];
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div6);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $leftEnter;
	let $keyboard;
	let $difficulty;
	let $colourBlind;
	let $dark;
	component_subscribe($$self, leftEnter, $$value => $$invalidate(0, $leftEnter = $$value));
	component_subscribe($$self, keyboard, $$value => $$invalidate(1, $keyboard = $$value));
	component_subscribe($$self, difficulty, $$value => $$invalidate(2, $difficulty = $$value));
	component_subscribe($$self, colourBlind, $$value => $$invalidate(3, $colourBlind = $$value));
	component_subscribe($$self, dark, $$value => $$invalidate(4, $dark = $$value));

	function toggleDark() {
		set_store_value(dark, $dark = setSetting('dark', !$dark, $dark), $dark);
		document.body.className = $dark ? 'dark' : '';
	}

	function toggleCb() {
		set_store_value(colourBlind, $colourBlind = setSetting('colourBlind', !$colourBlind, $colourBlind), $colourBlind);
	}

	function updateDifficulty(e) {
		set_store_value(difficulty, $difficulty = setSetting('difficulty', +e.target.value, $difficulty), $difficulty);
	}

	function updateKeyboard(e) {
		set_store_value(keyboard, $keyboard = setSetting('keyboard', e.target.value, $keyboard), $keyboard);
	}

	function toggleLeftEnter() {
		set_store_value(leftEnter, $leftEnter = setSetting('leftEnter', !$leftEnter, $leftEnter), $leftEnter);
	}

	return [
		$leftEnter,
		$keyboard,
		$difficulty,
		$colourBlind,
		$dark,
		toggleDark,
		toggleCb,
		updateDifficulty,
		updateKeyboard,
		toggleLeftEnter
	];
}

class Settings extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Settings;